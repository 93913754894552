import React from 'react'
import * as R from 'ramda'
import { Formik, FormikHelpers, Form } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import MDEditor from '@uiw/react-md-editor'
import {
  Button,
  Checkbox,
  FormLabel,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import Paper from 'lib/components/Paper'
import IProgram, {
  getProgramDayPath,
  getProgramDaysPath,
  getProgramPath,
  IProgramDay,
} from '@/pauses/models/IProgram'
import { getNavigationProp } from 'lib/utils/withNavigationProps'
import { RouteComponentProps } from 'react-router-dom'
import useModalState from 'lib/api/hooks/useModalState'
import { addDoc, deleteDoc, updateDoc } from 'lib/api/connection'
import FormInput from 'lib/components/FormInput'
import Dialog from 'lib/components/Dialog'
import useProgram from '@/pauses/hooks/useProgram'
import { UploadPauseAsset } from '../components/UploadAsset'
import ProgramDayEditor from '@/pauses/components/ProgramDayEditor'
import ExportProgramTranscriptsModal from '../components/ExportProgramTranscriptsModal'
import ModulesEditor from '../components/ModulesEditor'

type FormValues = IProgram & {
  days: IProgramDay[]
}

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'That is a pretty short title...')
    .required('Required'),
  description: Yup.string(),
  published: Yup.boolean().required('Required'),
  homeScreenOrder: Yup.number()
    .min(0, 'Home screen order must be greater than or equal to 0')
    .max(10, 'Home screen order must be less than or equal to 10')
    .integer('Home screen order must be a whole number'),
})

export default function ProgramDetailsScreen(props: RouteComponentProps) {
  const [deleteOpen, , , toggleDeleteOpen] = useModalState()
  const [isDayOperationPending, setIsDayOperationPending] = React.useState(
    false,
  )
  const [dayTabIndex, setDayTabIndex] = React.useState(0)
  const programId = getNavigationProp(props, 'id') as string
  const { program, days } = useProgram(programId)
  const [isExporting, setIsExporting] = React.useState<boolean>(false)

  const onSave = async (values: FormValues, bag: FormikHelpers<FormValues>) => {
    await program.update(R.dissoc('days', values))
    toast.success('Pause details saved!')
    bag.resetForm({ values })
  }

  const onCreateDay = async () => {
    setIsDayOperationPending(true)
    const order = days.data.length
    await addDoc(getProgramDaysPath(programId), {
      title: `Day ${order + 1}`,
      order,
    })
    await days.refetch()
    await program.update({ daysCount: (days.data.length || 0) + 1 })
    setDayTabIndex(order)
    setIsDayOperationPending(false)
  }

  const onDayDeleted = async (day: IProgramDay) => {
    setIsDayOperationPending(true)
    const updates = days.data
      .filter(d => d.id !== day.id)
      .map((d, order) => {
        const updated = { ...d, order }
        return updateDoc(getProgramDayPath(programId, d.id), updated)
      })
    await Promise.all(updates)
    await days.refetch()
    console.log('updating daysCount')
    await program.update({ daysCount: (days.data.length || 1) - 1 })
    setDayTabIndex(Math.max(0, day.order - 1))
    setIsDayOperationPending(false)
  }

  const onDeleteConfirmed = async () => {
    await deleteDoc(getProgramPath(programId))
    props.history.goBack()
  }

  if (!program.data) return <Spinner />

  return (
    <Formik
      initialValues={{
        ...{ published: false, homeScreenOrder: 0 }, // default values
        ...program.data,
        days: days.data,
      }}
      onSubmit={onSave}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {formikProps => {
        return (
          <div className="space-y-6">
            <Form className="space-y-6">
              <Paper
                title="Edit Program"
                stickyTop
                actions={
                  <div className="flex flex-row align-center space-x-2">
                    <Button
                      colorScheme="green"
                      type="submit"
                      isLoading={formikProps.isSubmitting}
                      isDisabled={!formikProps.dirty}
                    >
                      Save
                    </Button>
                    <Button
                      colorScheme="red"
                      type="button"
                      onClick={toggleDeleteOpen}
                      isLoading={formikProps.isSubmitting}
                    >
                      Delete
                    </Button>
                  </div>
                }
              >
                <div />
              </Paper>

              <Paper title="Program Details">
                <div className="space-y-6 divide-y">
                  <div className="flex flex-row items-center my-4">
                    <div className="flex flex-col flex-1 space-y-4">
                      <div>
                        <FormInput
                          label="Title"
                          name="title"
                          onChange={formikProps.handleChange}
                          value={formikProps.values.title}
                          placeholder="Program title"
                        />
                        <Checkbox
                          isChecked={formikProps.values.published}
                          onChange={formikProps.handleChange}
                          name="published"
                        >
                          Published
                        </Checkbox>
                        {formikProps.values.published && (
                          <FormInput
                            label="Home screen order (lower numbers appear first)"
                            name="homeScreenOrder"
                            placeholder="0-10"
                            onChange={formikProps.handleChange}
                            type="number"
                            value={
                              (formikProps.values.homeScreenOrder || 0) >= 0
                                ? formikProps.values.homeScreenOrder
                                : ''
                            }
                          />
                        )}
                      </div>

                      <div>
                        <FormLabel>Description</FormLabel>
                        <MDEditor
                          value={formikProps.values.description}
                          onChange={(newValue?: string | undefined) =>
                            formikProps.setFieldValue(
                              'description',
                              newValue || '',
                            )
                          }
                          preview="edit"
                          placeholder="Program Description"
                        />
                      </div>

                      <FormInput
                        label="Average Daily Length"
                        name="averageDailyLength"
                        onChange={formikProps.handleChange}
                        value={formikProps.values.averageDailyLength}
                        placeholder="18min/day"
                      />

                      <div className="flex flex-row space-x-4">
                        <UploadPauseAsset
                          pauseAssetId={programId}
                          type="IMAGE"
                          label="Background Image"
                          url={formikProps.values.backgroundImageAsset}
                          onUrlChanged={url =>
                            formikProps.setFieldValue(
                              'backgroundImageAsset',
                              url,
                            )
                          }
                        />
                        <UploadPauseAsset
                          pauseAssetId={programId}
                          type="VIDEO"
                          label="Intro Video"
                          url={formikProps.values.introVideoAsset}
                          onUrlChanged={url =>
                            formikProps.setFieldValue('introVideoAsset', url)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Paper>

              <Paper title="Modules">
                <ModulesEditor
                  modules={formikProps.values.modules}
                  onUpdate={modules => {
                    formikProps.setFieldValue('modules', modules)
                  }}
                />
              </Paper>
            </Form>

            <Paper
              title="Days"
              actions={
                <div className="flex flex-row align-center space-x-2">
                  <Button
                    colorScheme="green"
                    type="submit"
                    isDisabled={isExporting || formikProps.isSubmitting}
                    isLoading={isExporting}
                    onClick={() => setIsExporting(true)}
                  >
                    Export Transcripts
                  </Button>
                  <Button
                    colorScheme="green"
                    type="button"
                    isLoading={isDayOperationPending}
                    onClick={onCreateDay}
                  >
                    Add Day
                  </Button>
                </div>
              }
            >
              <Tabs
                index={dayTabIndex}
                onChange={index => setDayTabIndex(index)}
              >
                <div className="w-full overflow-x-auto">
                  <TabList>
                    {(formikProps.values.days || []).map((day, index) => (
                      <Tab key={day.id}>{index + 1}</Tab>
                    ))}
                  </TabList>
                </div>

                <TabPanels>
                  {(formikProps.values.days || []).map((day, index) => (
                    <TabPanel key={`day-tab-panel-${day.id}`}>
                      <ProgramDayEditor
                        programId={programId}
                        modules={formikProps.values.modules || {}}
                        day={day}
                        onDeleted={onDayDeleted}
                      />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </Paper>

            <Dialog
              isOpen={deleteOpen}
              message="Are you sure you want to delete this program?"
              onConfirm={onDeleteConfirmed}
              onDeny={toggleDeleteOpen}
              icon="delete"
              doubleConfirm={true}
            />

            {!!program.data && (
              <ExportProgramTranscriptsModal
                isOpen={isExporting}
                close={() => setIsExporting(false)}
                program={program.data}
                days={days.data}
              />
            )}
          </div>
        )
      }}
    </Formik>
  )
}
