import React from 'react'
import {
  Button,
  IconButton,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Editable,
  EditablePreview,
  EditableInput,
  Tooltip,
} from '@chakra-ui/react'
import { IModules, IModule } from '@/pauses/models/IProgram'
import { DeleteIcon } from '@chakra-ui/icons'

/**
 * Props
 * -----------------------------------------------------------------------------
 */

type Props = {
  modules?: IModules
  onUpdate: (modules: IModules) => void
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */

export default function ModulesEditor({ modules = {}, onUpdate }: Props) {
  /**
   * Module Object Helper Functions
   * ---------------------------------------------------------------------------
   */

  /** Create a new module. */
  const createModule = () => {
    const nextNumber = Object.keys(modules).length + 1
    const newModuleId = `module-${nextNumber}`
    onUpdate({ ...modules, [newModuleId]: { title: `Module ${nextNumber}` } })
  }

  /** Update a module ID. */
  const updateModuleID = (oldId: string, newId: string) => {
    const module = modules[oldId]
    const { [oldId]: _, ...updatedModules } = modules
    onUpdate({ ...updatedModules, [newId]: module })
  }

  /** Update a module property. */
  const updateModule = (moduleId: string, partialModule: Partial<IModule>) => {
    const updatedModules = {
      ...modules,
      [moduleId]: { ...modules[moduleId], ...partialModule },
    }
    onUpdate(updatedModules)
  }

  /** Delete a module. */
  const deleteModule = (moduleId: string) => {
    const { [moduleId]: _, ...updatedModules } = modules
    onUpdate(updatedModules)
  }

  /**
   * Module Object Helper Functions
   * ---------------------------------------------------------------------------
   */

  const isValidModuleId = (id: string): boolean => /^[a-z0-9-]+$/.test(id)

  const isValidModuleTitle = (title: string): boolean =>
    /^[a-zA-Z0-9\s-]+$/.test(title)

  const isExistingModuleId = (id: string): boolean => id in modules

  /**
   * Render
   * ---------------------------------------------------------------------------
   */

  return (
    <div className="flex flex-col">
      <TableContainer>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Title</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(modules)
              .sort()
              .map(([moduleId, module], index) => (
                <Tr key={index}>
                  <Td className="w-1/4">
                    <Editable
                      value={moduleId}
                      isDisabled
                      onChange={text => {
                        if (
                          isValidModuleId(text) &&
                          !isExistingModuleId(text)
                        ) {
                          updateModuleID(moduleId, text)
                        }
                      }}
                    >
                      <EditablePreview />
                      <EditableInput />
                    </Editable>
                  </Td>
                  <Td>
                    <Editable
                      value={module.title}
                      onChange={text => {
                        if (isValidModuleTitle(text)) {
                          updateModule(moduleId, { title: text })
                        }
                      }}
                    >
                      <EditablePreview />
                      <EditableInput />
                    </Editable>
                  </Td>
                  <Td className="w-1/4">
                    <Tooltip label="Delete Module">
                      <IconButton
                        icon={<DeleteIcon />}
                        aria-label="Delete Module"
                        size="md"
                        onClick={() => deleteModule(moduleId)}
                      />
                    </Tooltip>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <div className="flex flex-row justify-end mt-4">
        <Tooltip label="Add Module">
          <Button onClick={createModule}>+</Button>
        </Tooltip>
      </div>
    </div>
  )
}
