import { DEFAULT_ORG_V2_PAUSES_PATH } from 'lib/api/apiConstants'

export interface IPauseTranscript {
  id: string
  start: number
  end: number
  transcript: string
  paragraphEnd?: boolean
}

export interface IPauseBreathingConfig {
  iterations: number
  backgroundColor?: string
  startingMessage?: string
  startingDuration?: number
  inLength: number
  inMessage?: string
  holdInLength: number
  outLength: number
  outMessage?: string
  holdOutLength: number
}

export default interface IPause {
  id: string
  published: boolean
  showOnHomeScreen: boolean
  homeScreenOrder: number
  title: string
  duration?: number
  breathing?: IPauseBreathingConfig
  transcripts?: IPauseTranscript[]
  voiceAsset?: string
  breathingSoundAsset?: string
  videoAsset?: string
  voiceOnlyAsset?: string
  musicOnlyAsset?: string
  backgroundImageAsset?: string
}

export const getPausePath = (id: string) =>
  `${DEFAULT_ORG_V2_PAUSES_PATH}/${id}`

export const getPauseTranscriptsPath = (pauseId: string) =>
  `${getPausePath(pauseId)}/transcripts`

export const getPauseTranscriptPath = (pauseId: string, transcriptId: string) =>
  `${getPausePath(pauseId)}/transcripts/${transcriptId}`
