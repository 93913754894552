import React from 'react'
import * as R from 'ramda'
import { Formik, FormikHelpers, Form } from 'formik'
import * as Yup from 'yup'
import MDEditor from '@uiw/react-md-editor'
import { Button, Checkbox, FormLabel, IconButton } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { deleteDoc, updateDoc } from 'lib/api/connection'
import useModalState from 'lib/api/hooks/useModalState'
import Dialog from 'lib/components/Dialog'
import {
  getProgramDayPath,
  IProgramDay,
  IProgramDayActivity,
  IModules,
} from '@/pauses/models/IProgram'
import FormInput from 'lib/components/FormInput'
import Paper from 'lib/components/Paper'
import IPause from '@/pauses/models/IPause'
import AddPauseModal from '@/pauses/components/AddPauseModal'
import { DeleteIcon } from '@chakra-ui/icons'
import FormSelect from 'lib/components/FormSelect'

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  description: Yup.string(),
  moduleId: Yup.string(),
})

type Props = {
  programId: string
  modules: IModules
  day: IProgramDay
  onDeleted: (day: IProgramDay) => Promise<any>
}

export default function ProgramDayEditor({
  programId,
  modules,
  day,
  onDeleted,
}: Props) {
  const [addOpen, , , toggleAddOpen] = useModalState()
  const [deleteOpen, , , toggleDeleteOpen] = useModalState()

  const onSave = async (
    values: IProgramDay,
    bag: FormikHelpers<IProgramDay>,
  ) => {
    await updateDoc(getProgramDayPath(programId, day.id), values)
    bag.resetForm({ values })
    bag.setSubmitting(false)
  }

  const onDelete = async () => {
    await deleteDoc(getProgramDayPath(programId, day.id))
    await onDeleted(day)
  }

  return (
    <Formik
      initialValues={day}
      onSubmit={onSave}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {formikProps => {
        const onPauseAdded = (pause: IPause) => {
          const activity: IProgramDayActivity = {
            type: 'pause',
            title: pause.title,
            refId: pause.id,
            duration: pause.duration,
          }
          formikProps.setFieldValue('activities', [
            ...(formikProps.values.activities || []),
            activity,
          ])
          toggleAddOpen()
        }

        return (
          <Form className="m-4">
            <Paper
              title={`Day ${day.order + 1}`}
              actions={
                <div className="flex flex-row align-center space-x-2">
                  <Button
                    colorScheme="green"
                    type="submit"
                    isLoading={formikProps.isSubmitting}
                    onClick={() => formikProps.handleSubmit()}
                    isDisabled={!formikProps.dirty}
                  >
                    Save
                  </Button>
                  <Button
                    colorScheme="red"
                    type="button"
                    onClick={toggleDeleteOpen}
                    isLoading={formikProps.isSubmitting}
                  >
                    Delete
                  </Button>
                </div>
              }
            >
              <div className="flex flex-col flex-1 space-y-4">
                <FormInput
                  label="Title"
                  name="title"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.title || ''}
                  placeholder="Day title"
                />

                <div>
                  <FormLabel>Description</FormLabel>
                  <MDEditor
                    value={formikProps.values.description || ''}
                    onChange={(newValue?: string | undefined) =>
                      formikProps.setFieldValue('description', newValue || '')
                    }
                    height={80}
                    preview="edit"
                    placeholder="Day Description"
                    hideToolbar
                  />
                </div>

                <FormSelect
                  label="Module"
                  name="moduleId"
                  value={formikProps.values.moduleId}
                  onChange={event => {
                    formikProps.setFieldValue('moduleId', event.target.value)
                  }}
                  options={[
                    { label: 'None', value: 'none' },
                    ...Object.entries(modules).map(([id, module]) => ({
                      label: module.title,
                      value: id,
                    })),
                  ]}
                />

                <div className="space-y-4 pt-8">
                  <h2 className="text-xl font-semibold">Pauses</h2>

                  {formikProps.values.activities?.map((activity, index) => (
                    <div key={activity.refId} className="p-2 shadow space-y-2">
                      <div className="flex flex-col md:flex-row md:space-x-2">
                        <FormInput
                          label="Title"
                          name={`activities.${index}.title`}
                          onChange={formikProps.handleChange}
                          value={activity.title}
                          placeholder="Morning Devotional"
                        />
                        <FormInput
                          label="Duration"
                          type="number"
                          name={`activities.${index}.duration`}
                          onChange={formikProps.handleChange}
                          value={activity.duration}
                          placeholder="3 min"
                        />
                        <IconButton
                          icon={<DeleteIcon />}
                          aria-label="Delete Pause"
                          size="md"
                          onClick={() => {
                            const activities = [
                              ...(formikProps.values.activities || []),
                            ]
                            activities.splice(index, 1)
                            formikProps.setFieldValue('activities', activities)
                          }}
                        />
                      </div>
                      <Checkbox
                        isChecked={!!activity.required}
                        onChange={formikProps.handleChange}
                        name={`activities.${index}.required`}
                      >
                        Required
                      </Checkbox>

                      {(formikProps.values.activities?.length || 0) > 1 && (
                        <div className="flex flex-col space-y-2 pt-4">
                          <FormLabel>Required Pauses</FormLabel>
                          {formikProps.values.activities?.map((a, subIndex) => {
                            if (index === subIndex) return null
                            const isChecked = (
                              activity.requiredActivityIds || []
                            ).includes(a.refId)
                            return (
                              <Checkbox
                                key={`required-by-${index}-${subIndex}-${a.refId}`}
                                isChecked={isChecked}
                                onChange={() =>
                                  formikProps.setFieldValue(
                                    `activities.${index}.requiredActivityIds`,
                                    isChecked
                                      ? R.without(
                                          activity.requiredActivityIds || [],
                                          [a.refId],
                                        )
                                      : R.uniq([
                                          ...(activity.requiredActivityIds ||
                                            []),
                                          a.refId,
                                        ]),
                                  )
                                }
                                name={`activities.${index}.required`}
                              >
                                {a.title}
                              </Checkbox>
                            )
                          })}
                        </div>
                      )}
                      <Link
                        className="text-blue-500 pt-4 inline-block"
                        to={`/v2pauses/${activity.refId}`}
                      >
                        Edit linked pause
                      </Link>
                    </div>
                  ))}

                  <Button onClick={toggleAddOpen}>Add Pause</Button>
                </div>
              </div>
            </Paper>

            {deleteOpen && (
              <Dialog
                isOpen={deleteOpen}
                message="Are you sure you want to delete this program day?"
                onConfirm={onDelete}
                onDeny={toggleDeleteOpen}
                icon="delete"
              />
            )}

            <AddPauseModal
              isOpen={addOpen}
              onRequestClose={toggleAddOpen}
              onPauseAdded={onPauseAdded}
            />
          </Form>
        )
      }}
    </Formik>
  )
}
