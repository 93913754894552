import React, { MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { Flex, Box, Text } from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import IProgram from '@/pauses/models/IProgram'
import colors from 'lib/styles/colors'

interface IProps extends IProgram {
  onDelete: (program: IProgram) => any
}

export default function ProgramRow(props: IProps) {
  const onDelete = async (e: MouseEvent) => {
    e.preventDefault()
    await props.onDelete(props)
  }

  return (
    <Link to={`/programs/${props.id}`}>
      <Flex
        p={3}
        bg="white"
        direction="row"
        justify="space-between"
        align="center"
        cursor="pointer"
      >
        <Box>
          <Text fontSize="lg">{props.title}</Text>
          <Flex direction="row" align="center">
            <Text color={!props.published ? colors.error : ''}>
              {props.published ? 'Published' : 'Unpublished'}
            </Text>
          </Flex>
        </Box>

        <Flex direction="row" align="center">
          <Box onClick={onDelete} cursor="pointer" mr={2}>
            <DeleteIcon />
          </Box>
        </Flex>
      </Flex>
    </Link>
  )
}
