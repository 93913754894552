import React, { useRef } from 'react'
import clsx from 'clsx'
import * as R from 'ramda'
import { toast } from 'react-toastify'
import {
  Button,
  FormLabel,
  Spinner,
  Switch,
  Text,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import { RouteComponentProps } from 'react-router'
import { orderBy } from 'firebase/firestore'
import { getNavigationProp } from 'lib/utils/withNavigationProps'
import useDoc from 'lib/api/hooks/useDoc'
import { getPausePath } from 'domains/pauses/models/IPause'
import IPause, {
  getPauseTranscriptPath,
  getPauseTranscriptsPath,
  IPauseTranscript,
} from '@/pauses/models/IPause'
import useCollection from 'lib/api/hooks/useCollection'
import CaptionFileInput from '@/pauses/components/CaptionFileInput'
import Paper from 'lib/components/Paper'
import TranscriptRow from '@/pauses/components/TranscriptRow'
import TranscriptTimeMarker from '@/pauses/components/TranscriptTimeMarker'
import useModalState from 'lib/api/hooks/useModalState'
import Dialog from 'lib/components/Dialog'
import { addDoc, deleteDoc, updateDoc } from 'lib/api/connection'

const TIME_MARKERS_PER_SECOND = 4

export default function PauseTranscriptsEditorScreen(
  props: RouteComponentProps,
) {
  const audioRef = useRef<HTMLAudioElement>(null)
  const intervalRef = useRef<NodeJS.Timeout>(null)
  const pauseId = getNavigationProp(props, 'id') as string
  const pausePath = getPausePath(pauseId, true)
  const [pause] = useDoc<IPause>(pausePath)
  const transcriptsPath = getPauseTranscriptsPath(pauseId)
  const [transcripts, , fetchTranscripts] = useCollection<IPauseTranscript[]>(
    transcriptsPath,
    [orderBy('start', 'asc')],
  )

  const onTranscriptsChanged = React.useCallback(async () => {
    await fetchTranscripts()
  }, [fetchTranscripts])

  const [isPlaying, setIsPlaying] = React.useState(false)
  const [duration, setDuration] = React.useState(0)
  const msPerPx = 10
  const [timeMarkers, setTimeMarkers] = React.useState<null[]>([])
  const [positionMillis, setPositionMillis] = React.useState(0)
  const [syncScroll, setSyncScroll] = React.useState(true)

  const onLoadedMetadata = () => {
    const audioDuration = (audioRef.current?.duration || 0) * 1000
    setDuration(audioDuration)
    const count = Math.ceil(audioDuration / (1000 / TIME_MARKERS_PER_SECOND))
    const newTimeMarkers = R.repeat(null, count)
    setTimeMarkers(newTimeMarkers)
  }

  React.useEffect(() => {
    if (pause)
      if (!pause?.voiceAsset)
        toast.error('Please add a voice audio track to this pause...', {
          autoClose: false,
        })
  }, [pause])

  /** Add spacebar shortcut to play/pause audio */
  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // determine if the target is an input or textarea
      const tagName: string | undefined =
        (e.target as any)?.tagName || undefined
      const isInput = tagName === 'INPUT' || tagName === 'TEXTAREA'

      // if the current target is not an input or textarea, play/pause the audio
      if (e.code === 'Space' && !isInput) {
        e.preventDefault()
        if (audioRef.current) {
          audioRef.current.paused
            ? audioRef.current.play()
            : audioRef.current.pause()
        }
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  /** Seek audio to the given position in milliseconds. */
  const seekAudioToMs = React.useCallback((positionMs: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = positionMs / 1000
    }
  }, [])

  const onTimeChanged = React.useCallback(() => {
    const position = Math.round((audioRef.current?.currentTime || 0) * 1000)
    setPositionMillis(position)
    if (syncScroll)
      window.scrollTo({ top: position / msPerPx - 64, behavior: 'auto' })
  }, [msPerPx, syncScroll])

  React.useEffect(() => {
    const audio = audioRef.current
    if (!audio) return

    if (isPlaying) {
      if (intervalRef.current) clearInterval(intervalRef.current)
      // @ts-ignore
      intervalRef.current = setInterval(onTimeChanged, 100)
    }

    return function unSub() {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [isPlaying, onTimeChanged])

  const [mergeOpen, , , toggleMergeOpen] = useModalState()
  const [mergeIndex, setMergeIndex] = React.useState(-1)

  const onMergeWithPrevious = (index: number) => async () => {
    const firstTranscript = transcripts[index - 1]
    const secondTranscript = transcripts[index]
    if (firstTranscript && secondTranscript) {
      setMergeIndex(index)
      toggleMergeOpen()
      await fetchTranscripts()
    }
  }

  const onMergeWithPreviousConfirmed = async () => {
    const firstTranscript = transcripts[mergeIndex - 1]
    const secondTranscript = transcripts[mergeIndex]
    await deleteDoc(getPauseTranscriptPath(pauseId, secondTranscript.id))
    await updateDoc(getPauseTranscriptPath(pauseId, firstTranscript.id), {
      ...firstTranscript,
      end: secondTranscript.end,
      transcript: `${firstTranscript.transcript} ${secondTranscript.transcript}`,
    })
    onTranscriptsChanged()
    toggleMergeOpen()
  }

  const [splitOpen, , , toggleSplitOpen] = useModalState()
  const [splitIndex, setSplitIndex] = React.useState(-1)

  const onSplitTranscript = (index: number) => async () => {
    setSplitIndex(index)
    toggleSplitOpen()
    await fetchTranscripts()
  }

  const onSplitTranscriptConfirmed = async () => {
    const transcript = transcripts[splitIndex]
    if (!transcript) {
      setSplitIndex(-1)
      return
    }

    const mid = Math.round((transcript.end - transcript.start) / 2)
    await updateDoc(getPauseTranscriptPath(pauseId, transcript.id), {
      ...transcript,
      end: mid,
    })
    await addDoc(getPauseTranscriptsPath(pauseId), {
      transcript: transcript.transcript,
      end: transcript.end,
      start: mid,
      paragraphEnd: transcript.paragraphEnd,
    } as IPauseTranscript)
    onTranscriptsChanged()
    toggleSplitOpen()
  }

  if (!pause || (pause && !pause.voiceAsset)) {
    return <Spinner />
  }

  return (
    <div className="space-y-6 pb-36 container mx-auto">
      <div
        className={clsx(
          'fixed bottom-6 left-4 right-4 md:left-24 md:right-24 bg-gray-800',
          'shadow-xl z-50 rounded-md flex flex-row items-center space-x-4 p-4',
        )}
      >
        <div className="flex flex-col space-y-1 items-center">
          <p className="text-white text-xs">Sync Scroll</p>
          <Switch
            name="syncScroll"
            isChecked={syncScroll}
            onChange={() => setSyncScroll(!syncScroll)}
          >
            <div />
          </Switch>
        </div>
        <audio
          ref={audioRef}
          controls={true}
          className="flex flex-1"
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onLoadedMetadata={onLoadedMetadata}
          onSeeked={onTimeChanged}
        >
          <source src={pause.voiceAsset} />
        </audio>
      </div>

      <Paper
        title="Transcripts"
        actions={
          <div className="flex flex-row align-center space-x-2">
            <Button
              colorScheme="green"
              type="button"
              onClick={() => props.history.goBack()}
            >
              Back to Pause Details
            </Button>
          </div>
        }
      >
        <div className="space-y-6">
          <FormLabel id="transcriptsLabel" htmlFor="transcripts">
            Import from transcript file (.srt)
          </FormLabel>
          <CaptionFileInput
            pauseId={pauseId}
            name="transcripts"
            currentTranscripts={transcripts}
            onUpdated={onTranscriptsChanged}
          />
        </div>
      </Paper>

      <Paper title="Visual Editor">
        {transcripts.length <= 1 && (
          <div className="ml-4 mb-8">
            <Text className="mb-4">
              NOTE: When a Pause has only <em>one</em> transcript item, the
              transcript item is:
            </Text>
            <List spacing={3} className="ml-4">
              <ListItem>
                <ListIcon as={CheckIcon} color="green.500" />
                Not synced to the audio
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="green.500" />
                Displayed either full-screen or closed
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="green.500" />
                User-scrollable for the duration of the pause
              </ListItem>
            </List>
          </div>
        )}

        <div className="flex flex-row relative w-full">
          <div
            className="bg-gray-200 flex flex-col items-center relative"
            style={{ height: duration / msPerPx }}
          >
            {/* Track Position Thumb */}
            <div
              className="w-full h-1 bg-blue-500 absolute"
              style={{ top: positionMillis / msPerPx }}
            />

            <div className="pl-2">
              {timeMarkers.map((_, index) => (
                <TranscriptTimeMarker
                  key={`time-marker-${index}`}
                  pauseId={pauseId}
                  index={index}
                  msPerPx={msPerPx}
                  timeMarkersPerSecond={TIME_MARKERS_PER_SECOND}
                  onTranscriptAdded={onTranscriptsChanged}
                  onClick={positionMs => seekAudioToMs(positionMs)}
                />
              ))}
            </div>
          </div>

          <div className="w-full h-full flex flex-col relative ml-2">
            {transcripts.map((transcript, index) => (
              <TranscriptRow
                key={`${transcript.start}-${transcript.end}-${index}`}
                pauseId={pauseId}
                transcript={transcript}
                msPerPx={msPerPx}
                onDeleted={onTranscriptsChanged}
                hasPrevious={index > 0}
                onMergeWithPrevious={onMergeWithPrevious(index)}
                onSplitTranscript={onSplitTranscript(index)}
              />
            ))}
          </div>
        </div>
      </Paper>

      <Dialog
        isOpen={mergeOpen}
        message="Are you sure you want to merge with the previous transcript?"
        onConfirm={onMergeWithPreviousConfirmed}
        onDeny={() => {
          setMergeIndex(-1)
          toggleMergeOpen()
        }}
        icon="arrowUp"
      />

      <Dialog
        isOpen={splitOpen}
        message="Are you sure you want to split this transcript?"
        onConfirm={onSplitTranscriptConfirmed}
        onDeny={() => {
          setSplitIndex(-1)
          toggleSplitOpen()
        }}
        icon="copy"
      />
    </div>
  )
}
