import React, { useState } from 'react'
import { orderBy } from 'firebase/firestore'
import uuid from 'uuid'
import { List } from 'lib/components'
import { DEFAULT_ORG_PROGRAMS_PATH } from 'lib/api/apiConstants'
import Dialog from 'lib/components/Dialog'
import { hasValue } from 'lib/utils'
import { Box, Flex, Button } from '@chakra-ui/react'
import Paper from 'lib/components/Paper'
import { deleteDoc } from 'lib/api/connection'
import useModalState from 'lib/api/hooks/useModalState'
import ProgramRow from '@/pauses/components/ProgramRow'
import IProgram, { getProgramPath } from '@/pauses/models/IProgram'
import CreateProgramModal from '@/pauses/components/CreateProgramModal'

const order = [orderBy('homeScreenOrder', 'asc'), orderBy('title', 'asc')]

export default function ProgramsScreen() {
  const [fetched, setFetched] = React.useState(uuid())
  const [createOpen, , , toggleCreateOpen] = useModalState()

  const [deleteThisProgram, setDeleteProgram] = useState<IProgram | null>(null)
  const onDeleteRequested = (program: IProgram) => setDeleteProgram(program)
  const onDeleteDenied = () => setDeleteProgram(null)
  const onDeleteConfirmed = async () => {
    await deleteDoc(getProgramPath(deleteThisProgram!.id))
    setDeleteProgram(null)
    setFetched(uuid())
  }

  return (
    <Box>
      <Paper
        title="Programs"
        actions={
          <Flex direction="row" align="center">
            <Button onClick={toggleCreateOpen} colorScheme="green">
              Create New
            </Button>
          </Flex>
        }
      >
        <List
          collection={DEFAULT_ORG_PROGRAMS_PATH}
          RowComponent={ProgramRow}
          queries={order}
          rowProps={{
            onDelete: onDeleteRequested,
          }}
          refetch={fetched}
        />
      </Paper>

      <Dialog
        isOpen={hasValue(deleteThisProgram)}
        title={`Delete '${
          hasValue(deleteThisProgram)
            ? (deleteThisProgram as IProgram).title
            : ''
        }'?`}
        icon="delete"
        confirmVariant="red"
        onConfirm={onDeleteConfirmed}
        onDeny={onDeleteDenied}
        doubleConfirm={true}
      />

      <CreateProgramModal isOpen={createOpen} close={toggleCreateOpen} />
    </Box>
  )
}
