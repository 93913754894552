import React from 'react'
import { QueryConstraint } from 'firebase/firestore'
import useCollection from 'lib/api/hooks/useCollection'
import ListRaw from 'lib/components/ListRaw'
import uuid from 'uuid'

interface IProps {
  collection: string
  queries?: QueryConstraint[]
  keyExtractor?: (item: any) => string
  RowComponent: any
  DividerComponent?: any
  rowProps?: object
  /** optional: function to filter the collection */
  filter?: (item: any) => boolean
  /** optional: function to apply additional sorting to the collection */
  sort?: (a: any, b: any) => number
  refetch?: string
}

export default function List(props: IProps) {
  const [fetched, setFetched] = React.useState(props.refetch || uuid)
  const [collection, pending, fetch] = useCollection(
    props.collection,
    props.queries,
  )

  React.useEffect(() => {
    if (props.refetch && fetched !== props.refetch) {
      setFetched(props.refetch)
      fetch()
    }
  }, [fetched, props.refetch, fetch])

  // apply optional filters and sort
  const loadedCollection = pending ? [] : collection
  const filteredCollection = !!props.filter
    ? loadedCollection.filter(props.filter)
    : loadedCollection
  const finalCollection = !!props.sort
    ? filteredCollection.sort(props.sort)
    : filteredCollection

  return (
    <ListRaw
      keyExtractor={props.keyExtractor}
      RowComponent={props.RowComponent}
      rowProps={props.rowProps}
      DividerComponent={props.DividerComponent}
      items={finalCollection}
    />
  )
}
