import React, { useState } from 'react'
import uuid from 'uuid'
import { List } from 'lib/components'
import { DEFAULT_ORG_V2_PAUSES_PATH } from 'lib/api/apiConstants'
import CreatePauseModal from 'domains/pauses/components/CreatePauseModal'
import { RouteComponentProps } from 'react-router'
import Dialog from 'lib/components/Dialog'
import { hasValue } from 'lib/utils'
import { Box, Flex, Button, Spinner } from '@chakra-ui/react'
import Paper from 'lib/components/Paper'
import V2PauseRow from '@/pauses/components/V2PauseRow'
import useDuplicatePause from '../hooks/useDuplicatePause'
import ModalWrapper from 'lib/components/ModalWrapper'
import { deletePause } from '../workflow/deletePause'
import IPause from '../models/IPause'

export default function V2PausesScreen(props: RouteComponentProps) {
  const [fetched, setFetched] = React.useState(uuid())
  const [createOpen, setCreateOpen] = useState(false)
  const onCreateNewPause = () => setCreateOpen(true)
  const onCloseCreate = () => setCreateOpen(false)
  const [deleteThisPause, setDeletePause] = useState<IPause | null>(null)
  const onDeleteRequested = (pause: IPause) => setDeletePause(pause)
  const onDeleteDenied = () => setDeletePause(null)
  const onDeleteConfirmed = async () => {
    await deletePause(deleteThisPause!.id)
    setDeletePause(null)
    setFetched(uuid())
  }
  const [isDuplicatingPause, duplicatePause] = useDuplicatePause()
  const onDuplicateRequested = async (pause: IPause) => {
    await duplicatePause(pause.id)
    setFetched(uuid())
  }

  /**
   * Sort pauses by showOnHomeScreen and homeScreenOrder, then by title
   */
  const sortPauses = (a: IPause, b: IPause) => {
    if (a.showOnHomeScreen && !b.showOnHomeScreen) return -1
    if (!a.showOnHomeScreen && b.showOnHomeScreen) return 1
    if (a.showOnHomeScreen && b.showOnHomeScreen) {
      if (a.homeScreenOrder !== b.homeScreenOrder) {
        return a.homeScreenOrder - b.homeScreenOrder
      }
    }
    return a.title.localeCompare(b.title)
  }

  return (
    <Box>
      <Paper
        title="Pauses"
        actions={
          <Flex direction="row" align="center">
            <Button onClick={onCreateNewPause} colorScheme="green">
              Create New
            </Button>
          </Flex>
        }
      >
        <List
          collection={DEFAULT_ORG_V2_PAUSES_PATH}
          RowComponent={V2PauseRow}
          sort={sortPauses}
          rowProps={{
            onDelete: onDeleteRequested,
            onDuplicate: onDuplicateRequested,
          }}
          refetch={fetched}
        />
      </Paper>

      <Dialog
        isOpen={hasValue(deleteThisPause)}
        title={`Delete '${
          hasValue(deleteThisPause) ? (deleteThisPause as IPause).title : ''
        }'?`}
        icon="delete"
        confirmVariant="red"
        onConfirm={onDeleteConfirmed}
        onDeny={onDeleteDenied}
        doubleConfirm={true}
      />

      <ModalWrapper
        isOpen={isDuplicatingPause}
        title={'Duplicating Pause...'}
        actions={[]}
      >
        <Box textAlign="center">
          <Spinner />
        </Box>
      </ModalWrapper>

      <CreatePauseModal isOpen={createOpen} close={onCloseCreate} version={2} />
    </Box>
  )
}
