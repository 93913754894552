import React from 'react'
import Fuse from 'fuse.js'
import ModalWrapper from 'lib/components/ModalWrapper'
import IPause from '@/pauses/models/IPause'
import { Button, Input } from '@chakra-ui/react'
import { makeDebounce } from 'lib/utils/debounce'
import useCollection from 'lib/api/hooks/useCollection'
import { DEFAULT_ORG_V2_PAUSES_PATH } from 'lib/api/apiConstants'
import { orderBy } from 'firebase/firestore'

const { debounce } = makeDebounce()

const searchOptions = { keys: ['title'] }

type Props = {
  isOpen: boolean
  onRequestClose: () => any
  onPauseAdded: (pause: IPause) => any
}

export default function AddPauseModal(props: Props) {
  const [search, setSearch] = React.useState('')
  const searcher = React.useRef(new Fuse<IPause>([], searchOptions))
  const [pauses] = useCollection<IPause>(DEFAULT_ORG_V2_PAUSES_PATH, [
    orderBy('title', 'asc'),
  ])
  const [searchedPauses, setSearchedPauses] = React.useState<IPause[]>([])

  React.useEffect(() => {
    if (props.isOpen) {
      setSearch('')
      setSearchedPauses(pauses)
    }
  }, [props.isOpen, pauses])

  React.useEffect(() => {
    searcher.current = new Fuse(pauses, searchOptions)
    setSearchedPauses(pauses)
  }, [pauses])

  const onSearch = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(value)
    debounce(() => {
      if (value.length)
        setSearchedPauses(
          searcher.current.search(value).map(({ item }) => item),
        )
      else setSearchedPauses(pauses)
    })
  }

  return (
    <ModalWrapper
      title="Add Pause"
      isOpen={props.isOpen}
      actions={[
        {
          text: 'Cancel',
          colorScheme: 'gray',
          onClick: props.onRequestClose,
          extra: { type: 'button' },
        },
      ]}
    >
      <div className="space-y-4">
        <Input type="text" name="search" onChange={onSearch} value={search} />
        {searchedPauses.map(p => (
          <div
            key={p.id}
            className="flex flex-row items-center justify-between border-b border-gray-100"
          >
            <p>{p.title}</p>
            <Button colorScheme="green" onClick={() => props.onPauseAdded(p)}>
              Add
            </Button>
          </div>
        ))}
      </div>
    </ModalWrapper>
  )
}
