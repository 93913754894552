import React, { useContext, useState } from 'react'
import { Heading, Flex, Text, Button, Image } from '@chakra-ui/react'
import { RouteComponentProps } from 'react-router'
import { signOut } from 'firebase/auth'

import { RouterContext } from 'domains/application/screens/AppScreen'
import Dialog from 'lib/components/Dialog'
import { UserContext } from 'domains/application/screens/StartupScreen'
import IUser from 'domains/authentication/models/IUser'
import { hasValue } from 'lib/utils'
import colors from 'lib/styles/colors'
import { auth } from 'lib/api/connection'

export const HEADER_HEIGHT = 72
export const HEADER_HEIGHT_PX = `${HEADER_HEIGHT}px`

interface IProps {
  baseRoute?: string
  heading?: string
  subheading?: string
  onClick: () => any
}

function HeaderLink(props: IProps) {
  const router = useContext(RouterContext) as RouteComponentProps
  const onRoute = props.baseRoute
    ? router.location.pathname.startsWith(props.baseRoute)
    : false
  return (
    <Flex
      height={HEADER_HEIGHT_PX}
      direction="column"
      justify="center"
      cursor="pointer"
      onClick={props.onClick}
      ml={4}
      borderBottom={
        onRoute ? `solid 4px ${colors.primary}` : 'solid 4px transparent'
      }
    >
      {hasValue(props.heading) && (
        <Heading fontSize={['lg', 'xl', '2xl']} color={colors.primary}>
          {props.heading}
        </Heading>
      )}
      {hasValue(props.subheading) && <Text>{props.subheading}</Text>}
    </Flex>
  )
}

export default function Header() {
  const me = useContext(UserContext) as IUser
  const router = useContext(RouterContext) as RouteComponentProps
  // const onGoToPauses = () => router.history.push('/pauses')
  const onGoToPrograms = () => router.history.push('/programs')
  const onGoToPausesV2 = () => router.history.push('/v2pauses')
  const onGoToOrganization = () => router.history.push('/organization')
  const onGoToUsers = () => router.history.push('/users')

  const [logoutOpen, setLogoutOpen] = useState(false)
  const onLogout = () => setLogoutOpen(true)
  const onLogoutDenied = () => setLogoutOpen(false)
  const onLogoutConfirmed = async () => signOut(auth)

  return (
    <div className="sticky top-0 w-full bg-white z-50 shadow-lg">
      <div className="container mx-auto flex flex-row items-center justify-between">
        <Flex direction="row" align="center">
          <Image
            src="/images/icon.png"
            width={HEADER_HEIGHT_PX}
            height={HEADER_HEIGHT_PX}
          />
          <HeaderLink
            baseRoute="/organization"
            subheading="Organization"
            onClick={onGoToOrganization}
          />
          <HeaderLink
            baseRoute="/programs"
            subheading="Programs"
            onClick={onGoToPrograms}
          />
          <HeaderLink
            baseRoute="/v2pauses"
            subheading="Pauses"
            onClick={onGoToPausesV2}
          />
          {/* <HeaderLink
            baseRoute="/pauses"
            subheading="Pauses"
            onClick={onGoToPauses}
          /> */}
          <HeaderLink
            baseRoute="/users"
            subheading="Users"
            onClick={onGoToUsers}
          />
        </Flex>

        <Flex direction="column" align="center">
          <Button colorScheme="gray" onClick={onLogout}>
            <Text>Log out, {me.firstName}</Text>
          </Button>
        </Flex>

        <Dialog
          title="Are you sure you want to log out?"
          icon="lock"
          isOpen={logoutOpen}
          onConfirm={onLogoutConfirmed}
          onDeny={onLogoutDenied}
        />
      </div>
    </div>
  )
}
